import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class MedicalCertificatePage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = ["medical_cert_slider_phone1.png"];
    const sliderImageAlts = ["Screenshot of Medical Certificate"];
    const sliderImageIds = ["img1MedicalCertificateSlider"];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"medical_cert-bg.png"}
        sliderID="MedicalCertificateSlider"
        sliderTitle={t("Medical Certificate")}
        sliderTitleID={"textMedicalCertificateSliderTitle"}
        sliderText={t("Manage all your medical certificates in one place")}
        sliderTextID="textMedicalCertificateSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container one-slide page-container-${locale}`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textHealthNewsSection1Title"
                  className="second-feature-title"
                >
                  {t("Check out your records effortlessly")}
                </div>
                <div
                  id="textHealthNewsSection1Content"
                  className="second-feature-text"
                >
                  {t("Access your medical certificates at any time")}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="imgHealthNewsSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/medical_cert_p1_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="imgHealthNewsSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/medical_cert_p1_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section ">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textHealthNewsSection2Title"
                  className="second-feature-title"
                >
                  {t("Consolidate various types of medical certificates")}
                </div>
                <div
                  id="textHealthNewsSection2Content"
                  className="second-feature-text"
                >
                  {t("Easily share relevant certificates related to health")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="imgHealthNewsSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/medical_cert_p2_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="imgHealthNewsSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(`./resources/images/medical_cert_p2_2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(MedicalCertificatePage);
