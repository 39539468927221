import React, { Component } from "react";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";
import BannerSlider from "./BannerSlider";
import Swiper from "./Swiper";

class PromotionTruck extends Component {
  render() {
    const { locale, t } = this.props;

    return (
      <div
        className={` page-container page-container-${locale}`}
        id="MainContent"
      >
        <div
          data-aos="fade-up"
          data-aos-offset="-200"
          className="static_page_header"
        >
          <div className="container-full">
            <div className="row vertical-align">
              <div className="col-sm-12 banner-center-container">
                <img
                  id="imgCovidQRTopBannerImage"
                  data-aos-anchor-placement="center-center"
                  src={load(
                    `./resources/images/promulgation/animation/${locale}/promulgation-animation-main-img.jpg`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid promulgation-animation-container promulgation-animation-container-1">
          <div className="container ">
            <div
              className="row  "
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div className="col-lg-6 col-sm-12 single-animation-col">
                <div id="" className="promulgation-animation-intro">
                  <img
                    className="animation-episode-icon"
                    src={load(
                      `./resources/images/promulgation/animation/animation-e1-icon.png`
                    )}
                  />
                  <div class="animation-episode-info">
                    <span class="animation-episode">{t("Episode 1")}</span>
                    <span class="animation-episode-name">
                      {t("My Grandpas Secret to Longevity")}
                    </span>
                  </div>
                </div>
                <div
                  ref={this.youtubeContainer}
                  className="second-youtube-container"
                >
                  <iframe
                    key={locale}
                    className="second-responsive-iframe"
                    title={t("My Grandpa's Secret to Longevity")}
                    src="https://www.youtube.com/embed/uPA-6jCd7O8?si=Qbp5o5joqliuI2hK"
                    tabIndex="0"
                  ></iframe>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 single-animation-col">
                <div id="" className="promulgation-animation-intro">
                  <img
                    className="animation-episode-icon"
                    src={load(
                      `./resources/images/promulgation/animation/animation-e2-icon.png`
                    )}
                  />
                  <div class="animation-episode-info">
                    <span class="animation-episode">{t("Episode 2")}</span>
                    <span class="animation-episode-name">
                      {t("My Dad Lost Soul")}
                    </span>
                  </div>
                </div>
                <div
                  ref={this.youtubeContainer}
                  className="second-youtube-container"
                >
                  <iframe
                    key={locale}
                    className="second-responsive-iframe"
                    title={t("My Dad Lost Soul")}
                    src="https://www.youtube.com/embed/pqYwoZRmjeI?si=avApIk8gEhjTysXf"
                    tabIndex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid promulgation-animation-container">
          <div className="container ">
            <div
              className="row "
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div className="col-lg-6 col-sm-12 single-animation-col ">
                <div id="" className="promulgation-animation-intro">
                  <img
                    className="animation-episode-icon"
                    src={load(
                      `./resources/images/promulgation/animation/animation-e3-icon.png`
                    )}
                  />
                  <div class="animation-episode-info">
                    <span class="animation-episode">{t("Episode 3")}</span>
                    <span class="animation-episode-name">
                      {t("My Dads God Teammate")}
                    </span>
                  </div>
                </div>
                <div
                  ref={this.youtubeContainer}
                  className="second-youtube-container"
                >
                  <iframe
                    key={locale}
                    className="second-responsive-iframe"
                    title={t("My Dad’s God Teammate")}
                    src="https://www.youtube.com/embed/wpM74YNlK2E?si=UYzHhwPv4lQsP9vU"
                    tabIndex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid promulgation-animation-container">
          <div className="container ">
            <div
              className="row "
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div class="col-sm-12 animation-game">
                <div class="animation-game-title">
                  {t("Protect Personal Data Privacy Mini-game")}
                </div>
                <div class="animation-game-des">
                  {t(
                    "Let’s act as the “e-Health Warrior” to spot the differences between pictures!"
                  )}
                </div>
                <a href="/privacy-game" class="desktop-show">
                  <img
                    id=""
                    data-aos-anchor-placement="center-center"
                    src={load(
                      `./resources/images/promulgation/animation/${locale}/animation-game-banner.jpg`
                    )}
                    alt=" "
                  />
                </a>
                <a href="/privacy-game" class="mobile-show">
                  <img
                    id=""
                    data-aos-anchor-placement="center-center"
                    src={load(
                      `./resources/images/promulgation/animation/${locale}/animation-game-banner-mobile.jpg`
                    )}
                    alt=" "
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(PromotionTruck);
