import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";
import "../../../resources/styles/elifeCoins.scss";

class LifeCoins extends Component {
  render() {
    const { locale, t } = this.props;

    return (
      <div
        className={` page-container page-container-${locale} page-elife-coins`}
        id="MainContent"
      >
        <div className="hero-banner">
          <img
            className="desktop-show"
            src={load(
              `./resources/images/elife/${locale}/life-earn-point-banner-desktop.jpg`
            )}
          />
          <img
            className="mobile-show"
            src={load(
              `./resources/images/elife/${locale}/life-earn-point-banner-mobile.jpg`
            )}
          />
        </div>

        <div className="container life-earnpoint-table-container">
          <div className="row elife-box-content vertical-align">
            <div className="col-md-6">
              <h5>{t("earn coins intro sub title")}</h5>
              <h3>{t("earn coins intro title")}</h3>
              <h5>{t("earn coins intro content")}</h5>
            </div>
            <div className="col-md-6 life-tutorial-phone">
              <img src={load(`./resources/images/elife/coins-intro.png`)} />
            </div>
          </div>
          <div className="empty-space-100"></div>
          <div className="col-lg-6 col-sm-12 ">
            <h3>{t("Daily Missions")}</h3>
            <table className="earn-point-table">
              <tr>
                <th>{t("Daily Mission")}</th>
                <th>{t("Coins")}</th>
              </tr>
              <tr>
                <td>{t("Daily Game Play")}</td>
                <td>10</td>
              </tr>
              <tr>
                <td>{t("Collect first character")}</td>
                <td>20</td>
              </tr>
              <tr>
                <td>{t("Collect first gadget")}</td>
                <td>20</td>
              </tr>
              <tr>
                <td>{t("Collect 10 stickers (any) per day")}</td>
                <td>50</td>
              </tr>
              <tr>
                <td>{t("Total")}</td>
                <td>{t("55@day = 1650@30days")}</td>
              </tr>
            </table>

            <h3>{t("Remarks")}</h3>
            <div class="point-remarks">{t("Daily Max 300 per player")}</div>
            <table className="earn-point-table">
              <tr>
                <td width="160">10/{t("Character")}</td>
                <td>{t("Tier")}3</td>
                <td width="200">
                  <img
                    src={load(`./resources/images/elife/elife-coins-tier3.png`)}
                  />
                </td>
              </tr>
              <tr>
                <td width="160">5/{t("Character")}</td>
                <td>{t("Tier")}2</td>
                <td width="200">
                  <img
                    src={load(`./resources/images/elife/elife-coins-tier2.png`)}
                  />
                </td>
              </tr>
              <tr>
                <td width="160">
                  2/{t("Character")}
                  <br /> 2/{t("Gadget")}
                </td>
                <td>{t("Tier")}1</td>
                <td width="200">
                  <img
                    src={load(`./resources/images/elife/elife-coins-tier1.png`)}
                  />
                </td>
              </tr>
            </table>
          </div>

          <div className="col-lg-6 col-sm-12 ">
            <h3>{t("Main Missions")}</h3>
            <table className="earn-point-table">
              <tr>
                <th>{t("Main Mission")}</th>
                <th>{t("Coins")}</th>
              </tr>
              <tr>
                <td>{t("Complete pre-game survey")}</td>
                <td>20</td>
              </tr>
              <tr>
                <td>{t("Complete post-game survey")}</td>
                <td>30</td>
              </tr>
              <tr>
                <td>{t("Collect 5, 10, 20, 30, 40, 50, 60 characters")}</td>
                <td>20x7=140</td>
              </tr>
              <tr>
                <td>
                  {t(
                    "Collect 50, 75, 100, 150, 200, 250,300 stickers (any) accumulated"
                  )}
                </td>
                <td>20x7=140</td>
              </tr>
              <tr>
                <td>{t("Complete energy collection in park")}</td>
                <td>20x30=600</td>
              </tr>
              <tr>
                <td>{t("Collect all tiers characters")}</td>
                <td>15</td>
              </tr>
              <tr>
                <td>{t("Collect all first tier characters")}</td>
                <td>10</td>
              </tr>
              <tr>
                <td>{t("Collect all second tiers characters")}</td>
                <td>10</td>
              </tr>
              <tr>
                <td>{t("Collect all third tier characters")}</td>
                <td>10</td>
              </tr>
              <tr>
                <td>{t("Continue 3, 7, 14,21,30,60,90 days + 1 sticker")}</td>
                <td>10x5=50</td>
              </tr>
              <tr>
                <td>{t("Visit new park + 1 stickers")}</td>
                <td>5x30=150</td>
              </tr>
              <tr>
                <td>{t("Total")}</td>
                <td>1175</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(LifeCoins);
