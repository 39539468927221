import React, { Component } from 'react'
import load from '../../ImageLoder';
import PageHOC from '../../PageHOC';
import Footer from '../Footer';

class EppPrivacyPage extends Component {
    render() {
        const {locale, t} = this.props;
        let {languageCodeMap} = this.props;
        let ehealthPrivacyPolicyURL = `https://www.ehealth.gov.hk/${languageCodeMap[locale]['code']}/privacy-policy/index.html`;
        let personalInfoCollectURL = `https://www.ehealth.gov.hk/${languageCodeMap[locale]['code']}/you-and-your-family/support/hcr-personal-information-collection-statement.html`;

        return (
            <div className={`page-container page-container-${locale}`} id="MainContent">
                <div className="empty-space-120"></div>

                <div className="epp-privacy-background">
                        <div className="epp-privacy-container">
                            <div id="eppPrivacyTitle" className="epp-privacy-title">{t("EPP Privacy Title 1")}</div>
                            <div id="eppPrivacyContent" className="epp-privacy-content">
                                <p>{t("EPP Privacy Content 1")}</p>
                                <p>{t("EPP Privacy Content 2")}</p>
                                <p>{t("EPP Privacy Content 3")}
                                <a id="ehealthPrivacyPolicyURL" href={ehealthPrivacyPolicyURL} target="_blank" role="text" tabIndex="0" aria-label={ehealthPrivacyPolicyURL}>{ehealthPrivacyPolicyURL}</a></p>
                                <p>{t("EPP Privacy Content 4")}</p>
                                <p>{t("EPP Privacy Content 5")}</p>
                                <p>{t("EPP Privacy Content 6")}</p>
                                <p>{t("EPP Privacy Content 7")}</p>
                                <p>{t("EPP Privacy Content 8")}</p>
                                <p>{t("EPP Privacy Content 9")}</p>
                                <p>{t("EPP Privacy Content 10")}</p>
                                <p>{t("EPP Privacy Content 11")}</p>
                                <p>{t("EPP Privacy Content 12")}</p>
                                <p>{t("EPP Privacy Content 13")}</p>
                                <p>{t("EPP Privacy Content 14")}</p>
                            </div>
                            <div id="eppPrivacyTitle2" className="epp-privacy-title">{t("EPP Privacy Title 2")}</div>
                            <div id="eppPrivacyContent2" className="epp-privacy-content">
                                <p>{t("EPP Privacy Content 15")}</p>
                                <p>{t("EPP Privacy Content 16")}</p>
                                <p>{t("EPP Privacy Content 17")}</p>
                            </div>
                            <div id="eppPrivacyTitle3" className="epp-privacy-title">{t("EPP Privacy Title 3")}</div>
                            <div id="eppPrivacyContent3" className="epp-privacy-content">
                                <p>{t("EPP Privacy Content 18")}</p>
                            </div>
                            <div id="eppPrivacyTitle4" className="epp-privacy-title">{t("EPP Privacy Title 4")}</div>
                            <div id="eppPrivacyContent4" className="epp-privacy-content">
                                <p>{t("EPP Privacy Content 19")}
                                <a id="personalInfoCollectURL" href={personalInfoCollectURL} target="_blank" role="text" tabIndex="0" aria-label={personalInfoCollectURL}>{personalInfoCollectURL}</a></p>                               
                            </div>
                        </div>
                </div>

                <Footer {...this.props} />

            </div>
        )
    }
}

export default PageHOC(EppPrivacyPage);
