import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class ChildGrowthPage extends Component {
  constructor(props) {
    super(props);
    this.youtubeContainer = React.createRef();
  }
  componentDidMount() {
    this.setVideoHeight();
    window.addEventListener("resize", this.setVideoHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setVideoHeight);
  }

  setVideoHeight = () => {
    this.youtubeContainer.current.style.height = `${
      (this.youtubeContainer.current.clientWidth * 9) / 16
    }px`;
  };

  render() {
    const { locale, t } = this.props;
    const sliderImages = [
      "slider_phone1.png",
      "slider_phone2.png",
      "slider_phone3.png",
    ];
    const sliderImageAlts = [
      "Screenshot of Child Growth Record",
      "Screenshot of Child Growth Record",
      "Screenshot of Child Growth Record",
    ];
    const sliderImageIds = [
      "img1ChildGrowthSlider",
      "img2ChildGrowthSlider",
      "img3ChildGrowthSlider",
    ];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"childgrowth-bg.png"}
        sliderID="childGrowthSlider"
        sliderTitle={t("Child Growth Record")}
        sliderTitleID={"textChildGrowthSliderTitle"}
        sliderText={t("Take care of your children's health")}
        sliderTextID="textChildGrowthSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textChildGrowthSection1Title"
                  className="second-feature-title"
                >
                  {t("Record the footprints of your children's growth")}
                </div>
                <div
                  id="textChildGrowthSection1Content"
                  className="second-feature-text"
                >
                  {t("Grasp the whole picture with clear data analysis")}
                </div>
              </div>

              <div data-aos="" className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1ChildGrowthSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/child_p1_1.png`)}
                  alt=" "
                />
                <img
                  id="img2ChildGrowthSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/${locale}/child_p1.png`)}
                  alt=" "
                />
                <img
                  id="img3ChildGrowthSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/child_p1_2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textChildGrowthSection2Title"
                  className="second-feature-title"
                >
                  {t("Capture every treasurable moments")}
                </div>
                <div
                  id="textChildGrowthSection2Content"
                  className="second-feature-text"
                >
                  {t(
                    "Create a photo album for your little one Share it with your family and friends"
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1ChildGrowthSection2"
                  data-aos="fade-up"
                  className="absolute-image middle"
                  src={load(`./resources/images/child_p2_1.png`)}
                  alt=" "
                />
                <img
                  id="img2ChildGrowthSection2"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  className="absolute-image  back"
                  src={load(`./resources/images/${locale}/child_p2.png`)}
                  alt=" "
                />
                <img
                  id="img3ChildGrowthSection2"
                  data-aos="fade-up"
                  className="absolute-image front"
                  src={load(`./resources/images/child_p2_2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section last-section">
          <div className="container ">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textChildGrowthSection3Title"
                  className="second-feature-title"
                >
                  {t(
                    "With abundant health information to support you in helping your children thrive"
                  )}
                </div>
                <div
                  id="textChildGrowthSection3Content"
                  className="second-feature-text"
                >
                  {t("To know about what your children need at every stage")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1ChildGrowthSection3"
                  data-aos="fade-up"
                  className="absolute-image back"
                  src={load(`./resources/images/child_p3_1.png`)}
                  alt=" "
                />
                <img
                  id="img2ChildGrowthSection3"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/${locale}/child_p3.png`)}
                  alt=" "
                />
                <img
                  id="img3ChildGrowthSection3"
                  data-aos="fade-up"
                  className="absolute-image back"
                  src={load(`./resources/images/child_p3_2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-down"
          data-aos-offset="-200"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoChildGrowth"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/dIo33I29pdY"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>
        <div className="container-fluid child_tips_HW ">
          <div className="container">
            <div className="row vertical-align">
              <div className="col-md-12">
                <div
                  id="textChildGrowthSection4Title"
                  data-aos="fade-up"
                  className="second-usage-title usage-mainTitle"
                >
                  {t("Useful Tips")}
                </div>
                <div
                  id="textChildGrowthSection4Content"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="second-usage-text usage-mainText"
                >
                  {t("How to share your children’s growth record?")}
                </div>
                <div
                  id="textChildGrowthSection4Content"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="second-usage-text usage-2nd-mainText"
                >
                  {t("Tap the Report and share the selected growth chart!")}
                </div>
                <img
                  id="imgChildGrowthSection4"
                  data-aos="fade-up"
                  data-aos-duration="2500"
                  src={load(`./resources/images/${locale}/child_usage0.png`)}
                  className="desktop-show"
                  alt=" "
                />
                <img
                  id="imgMobileChildGrowthSection4"
                  data-aos="fade-up"
                  data-aos-duration="2500"
                  src={load(
                    `./resources/images/${locale}/mobile_child_usage0.png`
                  )}
                  className="mobile-show"
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid child_tips_HW second-child-tips">
          <div className="container">
            <div className="row vertical-align">
              <div className="col-md-12">
                <div
                  id="textChildGrowthSection4Content"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="second-usage-text usage-mainText"
                >
                  {t("How to measure your children's height and weight?")}
                </div>
                <img
                  id="imgChildGrowthSection4"
                  data-aos="fade-up"
                  data-aos-duration="2500"
                  src={load(`./resources/images/${locale}/child_usage1.png`)}
                  className="desktop-show"
                  alt={t("Tap the light build to learn more!")}
                />
                <img
                  id="imgMobileChildGrowthSection4"
                  data-aos="fade-up"
                  data-aos-duration="2500"
                  src={load(
                    `./resources/images/${locale}/mobile_child_usage1.png`
                  )}
                  className="mobile-show"
                  alt={t("Tap the light build to learn more!")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid child_input_HW">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-sm-9 col-xs-12 col-sm-push-3"
              >
                {locale === "en" ? (
                  <div
                    id="textChildGrowthSection5Title"
                    className="second-usage-title"
                  >
                    {t("How to input children's height and weight?")}
                  </div>
                ) : (
                  <div
                    id="textChildGrowthSection5SubTitle"
                    className="second-usage-title"
                  >
                    <span className="small">{t("How to input")}</span>
                    <br />
                    {t("children's height and weight?")}
                  </div>
                )}
                <div
                  id="textChildGrowthSection5Content"
                  className="second-usage-text"
                >
                  {t(
                    "Simply scroll the measuring tape up and down, swipe the pound left and right to input the value"
                  )}
                </div>
              </div>
              <div
                data-aos="fade-right"
                className="col-sm-3 col-xs-12 col-sm-pull-9"
              >
                <img
                  id="imgChildGrowthSection5"
                  src={load(`./resources/images/${locale}/child_usage2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid child_unicorn">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-8 col-sm-12">
                <div
                  id="textChildGrowthSection6Title"
                  className="second-usage-title"
                >
                  {t("Any figures that you don't understand?")}
                  <br />
                </div>
                <div
                  id="textChildGrowthSection6Content"
                  className="second-usage-text"
                >
                  {t("Tap the Unicorn and Elephant to tell you more!")}
                </div>
              </div>

              <div
                data-aos="fade-up"
                data-aos-offset="-200"
                data-aos-anchor-placement="center-center"
                className="col-md-4 col-sm-12"
              >
                <img
                  id="imgChildGrowthSection6"
                  src={load(`./resources/images/child_usage3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(ChildGrowthPage);
