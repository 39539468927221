import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";
import BannerSlider from "./BannerSlider";
import Swiper from "./Swiper";

class PromulgationAdvertisement extends Component {
  render() {
    const { locale, t } = this.props;

    return (
      <div
        className={`promulgation-promotion-truck-page page-container page-container-${locale}`}
        id="MainContent"
      >
        <div
          data-aos="fade-up"
          data-aos-offset="-200"
          className="static_page_header"
        >
          <div className="container-full">
            <div className="row vertical-align">
              <div className="col-sm-12">
                <img
                  id="imgCovidQRTopBannerImage"
                  data-aos-anchor-placement="center-center"
                  src={load(
                    `./resources/images/promulgation/advertisement/ad-main-img.jpg`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container content-title-container">
            <div
              className="content-title promulgation-page-title"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <img
                className="content-title-icon"
                src={load(
                  `./resources/images/promulgation/landing/promulgation-landing-in-outdoor-ad-icon.png`
                )}
                alt=""
              />
              <span className="content-title-text">
                {t("advertisement title")}
              </span>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container">
            <div
              className="row vertical-align advertisement-item-row"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div className="col-md-6 col-sm-12">
                <span className="ad-item-title">
                  <img
                    src={load(
                      `./resources/images/promulgation/advertisement/bus-body-icon.png`
                    )}
                  ></img>
                  {t("Bus Body")}
                </span>
                <img
                  className="advertisement-photo"
                  src={load(
                    `./resources/images/promulgation/advertisement/ad-01.jpg`
                  )}
                  alt=""
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <span className="ad-item-title">
                  <img
                    src={load(
                      `./resources/images/promulgation/advertisement/mini-bus-body-icon.png`
                    )}
                  ></img>
                  {t("Minibus Body")}
                </span>
                <img
                  className="advertisement-photo"
                  src={load(
                    `./resources/images/promulgation/advertisement/ad-02.jpg`
                  )}
                  alt=""
                />
              </div>
            </div>

            <div
              className="row vertical-align advertisement-item-row"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div className="col-md-6 col-sm-12">
                <span className="ad-item-title">
                  <img
                    src={load(
                      `./resources/images/promulgation/advertisement/outdoor-tv-icon.png`
                    )}
                  ></img>
                  {t("Outdoor TV")}
                </span>
                <img
                  className="advertisement-photo"
                  src={load(
                    `./resources/images/promulgation/advertisement/ad-03.jpg`
                  )}
                  alt=""
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <span className="ad-item-title">
                  <img
                    src={load(
                      `./resources/images/promulgation/advertisement/mtr-icon.png`
                    )}
                  ></img>
                  {t("MTR Stations")}
                </span>
                <img
                  className="advertisement-photo"
                  src={load(
                    `./resources/images/promulgation/advertisement/ad-04.jpg`
                  )}
                  alt=""
                />
              </div>
            </div>

            <div
              className="row vertical-align advertisement-item-row"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div className="col-md-12 col-sm-12">
                <span className="ad-item-title">
                  <img
                    src={load(
                      `./resources/images/promulgation/advertisement/post-office.png`
                    )}
                  ></img>
                  {t("Post Office")}
                </span>

                <div className="row">
                  <div className="col-sm-6">
                    <img
                      className="advertisement-photo"
                      src={load(
                        `./resources/images/promulgation/advertisement/ad-05.jpg`
                      )}
                      alt=""
                    />
                  </div>

                  <div className="col-sm-6">
                    <img
                      className="advertisement-photo"
                      src={load(
                        `./resources/images/promulgation/advertisement/ad-06.jpg`
                      )}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="promulgation-back"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-offset="-200"
        >
          <a href="javascript:history.back()">{t("Back")}</a>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(PromulgationAdvertisement);
