import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";

class LifeOverview extends Component {
  render() {
    const { locale, t } = this.props;

    return (
      <div
        className={` page-container page-container-${locale} static-page-with-banner`}
        id="MainContent"
      >
        <div className="">
          <div className="container-full">
            <div className="row vertical-align">
              <img
                className="desktop-show"
                src={load(
                  `./resources/images/elife/${locale}/life-overview-banner-desktop.jpg`
                )}
              />
              <img
                className="mobile-show"
                src={load(
                  `./resources/images/elife/${locale}/life-overview-banner-mobile.jpg`
                )}
              />
            </div>
          </div>
        </div>

        <div className="container ">
          <div className="row">
            <div className="col-lg-12 col-sm-12 overview-intro0">
              <img
                className=""
                src={load(`./resources/images/life-overview-intro0.png`)}
              />
              {t("e+ Life Overview intro Content")}
            </div>
          </div>
          <div className="container life-overview-3col">
            <div className="overview-item col-lg-4 col-sm-12 ">
              <div class="overview-item-content">
                <img
                  className=""
                  src={load(`./resources/images/life-overview-intro1.png`)}
                />
                <h4>{t("e+ Life Point 1 Title")}</h4>
                {t("e+ Life Point 1 Content")}
              </div>
            </div>

            <div className="overview-item col-lg-4 col-sm-12 ">
              <div class="overview-item-content">
                <img
                  className=""
                  src={load(`./resources/images/life-overview-intro2.png`)}
                />
                <h4>{t("e+ Life Point 2 Title")}</h4>
                {t("e+ Life Point 2 Content")}
              </div>
            </div>

            <div className="overview-item col-lg-4 col-sm-12 ">
              <div class="overview-item-content">
                <img
                  className=""
                  src={load(`./resources/images/life-overview-intro3.png`)}
                />
                <h4>{t("e+ Life Point 3 Title")}</h4>
                {t("e+ Life Point 3 Content")}
              </div>
            </div>
          </div>
        </div>

        <div id="overview-park-container" className="container-fluid ">
          <div className="container">
            <h2 className="life-section-title">{t("e+ Go To Park")}</h2>

            <div className="row  vertical-align">
              <div className="col-md-4">
                <img
                  className="overview-park-main"
                  src={load(`./resources/images/life-overview-park-intro0.png`)}
                />
              </div>
              <div className="col-md-8 overview-park-intro">
                {t("e+ Go To Park Intro")}
              </div>
            </div>

            <div className="school-challenge elife-box-content">
              <div className="challenge-title">
                <img
                  className=""
                  src={load(`./resources/images/life-school-thumbnail.png`)}
                />
                <br />
                {t("e+ Go To Park School Challenge")}
              </div>
              <div className="row life-challenge-3col">
                <div className="challenge-item col-lg-4 col-sm-12 ">
                  <img
                    className=""
                    src={load(
                      `./resources/images/life-overview-park-intro1.png`
                    )}
                  />
                  <br />
                  {t("School Challenge Point 1")}
                </div>

                <div className="challenge-item col-lg-4 col-sm-12 ">
                  <img
                    className=""
                    src={load(
                      `./resources/images/life-overview-park-intro2.png`
                    )}
                  />
                  <br />
                  {t("School Challenge Point 2")}
                </div>

                <div className="challenge-item col-lg-4 col-sm-12 ">
                  <img
                    className=""
                    src={load(
                      `./resources/images/life-overview-park-intro3.png`
                    )}
                  />
                  <br />
                  {t("School Challenge Point 3")}
                </div>
              </div>
              <a className="leaderboard-link" href="elife-leaderboard">
                {t("Go to Leader board")}
              </a>
            </div>
          </div>
        </div>

        <div className="container life-overview-button-container">
          <div className="container">
            <div className="life-overview-button col-lg-6 col-sm-12 ">
              <a href="elife-coins">
                <img
                  className="life-overview-button-img"
                  src={load(`./resources/images/life-overview-point-btn.png`)}
                />
                {t("e+Life Coins")}
                <img
                  className="life-overview-button-arrow"
                  src={load(`./resources/images/overview-button-arrow.png`)}
                />
              </a>
            </div>
            <div className="life-overview-button col-lg-6 col-sm-12 ">
              <a href="elife-tutorial">
                <img
                  className="life-overview-button-img"
                  src={load(
                    `./resources/images/life-overview-tutorial-btn.png`
                  )}
                />
                {t("e+Life Tutorial")}
                <img
                  className="life-overview-button-arrow"
                  src={load(`./resources/images/overview-button-arrow.png`)}
                />
              </a>
            </div>
            <div className="life-overview-button col-lg-6 col-sm-12 ">
              <a href="elife-faq">
                <img
                  className="life-overview-button-img"
                  src={load(`./resources/images/life-overview-faq-btn.png`)}
                />
                {t("e+Life faq")}
                <img
                  className="life-overview-button-arrow"
                  src={load(`./resources/images/overview-button-arrow.png`)}
                />
              </a>
            </div>
            <div className="life-overview-button col-lg-6 col-sm-12 ">
              <a href="elife-parklist">
                <img
                  className="life-overview-button-img"
                  src={load(
                    `./resources/images/life-overview-parklist-btn.png`
                  )}
                />
                {t("e+Life Park List")}
                <img
                  className="life-overview-button-arrow"
                  src={load(`./resources/images/overview-button-arrow.png`)}
                />
              </a>
            </div>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(LifeOverview);
