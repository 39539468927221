import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";

class LifeTutorial extends Component {
  render() {
    const { locale, t } = this.props;

    return (
      <div
        className={` page-container page-container-${locale} life-tutorial-page`}
        id="MainContent"
      >
        <div className="static_page_header static_page_header_download">
          <div
            className="static_page_header_bg"
            style={{
              background: `url(${load(
                "./resources/images/life-faq-banner-bg.jpg"
              )}) no-repeat 0%`,
            }}
          ></div>
          <div className="container ">
            <div className="row vertical-align">
              <div className="col-sm-12 download-banner-graphic">
                <img
                  src={load(
                    `./resources/images/elife/${locale}/life-tutorial-banner-graphic.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <div className="container-fluid ">
          <div className="container">
            <h2 className="life-section-title">
              {t("Download Tutorial Title")}
            </h2>

            <div className="row elife-box-content vertical-align">
              <div className="col-md-4 life-tutorial-phone">
                <img
                  src={load(
                    `./resources/images/elife/${locale}/life-tutorial-download-phone.jpg`
                  )}
                />
              </div>
              <div className="col-md-8 life-tutorial-phone-intro">
                <h3>{t("Download Tutorial Sub Title")}</h3>
                <h5>{t("Download Tutorial Content")}</h5>
                <div className="col-md-4 tutorial-qr-col">
                  <img
                    className="tutorial-qr"
                    src={load(
                      `./resources/images/elife/life-tutorial-download-qr.jpg`
                    )}
                  />
                </div>
                <div className="col-sm-8 row life-download-btn">
                  <div className="col-xs-6 col-sm-6">
                    <a href="#" target="_blank">
                      <img
                        id="imgBtnDownloadAppStore"
                        src={load(`./resources/images/${locale}/app_store.png`)}
                        alt={t("Download at App Store")}
                      />
                    </a>
                  </div>
                  <div className="col-xs-6 col-sm-6">
                    <a href="#" target="_blank">
                      <img
                        id="imgBtnDownloadAppStore"
                        src={load(
                          `./resources/images/${locale}/google_play.png`
                        )}
                        alt={t("Download at Google Play")}
                      />
                    </a>
                  </div>
                  <div className="col-xs-6 col-sm-6">
                    <a href="#" target="_blank">
                      <img
                        id="imgBtnDownloadAppStore"
                        src={load(`./resources/images/${locale}/huawei.png`)}
                        alt={t("Download at Huawei App Gallery")}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="empty-space-100"></div>

          <div className="container">
            <div className="row tabs-row">
              <div class="tabs">
                <div class="tab active">{t("16 years old or above")}</div>
                <div class="tab ">{t("Under 16 years old")}</div>
              </div>

              <div>
                <div
                  ref={this.youtubeContainer}
                  className="second-youtube-container"
                >
                  <iframe
                    id="videoChildGrowth"
                    key={locale}
                    className="second-responsive-iframe"
                    title={t("Video des")}
                    src="https://www.youtube.com/embed/eWJY2MWm-nw"
                    alt={t("Video")}
                    tabIndex="0"
                  ></iframe>
                </div>
              </div>
              {/* <div className="life-tutorial-btn-row row">
                <div className="life-overview-button col-lg-push-3 col-lg-6 col-sm-12 ">
                  <a href="#">
                    <img
                      className="life-overview-button-img"
                      src={load(
                        `./resources/images/life-overview-tutorial-btn.png`
                      )}
                    />
                    按此下載PDF版本
                    <img
                      className="life-overview-button-arrow"
                      src={load(`./resources/images/overview-button-arrow.png`)}
                    />
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(LifeTutorial);
