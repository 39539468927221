import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class CBHRPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = ["cbhr_slider_phone1.png"];
    const sliderImageAlts = ["Screenshot of Personal Folder"];
    const sliderImageIds = ["img1PersonalFolderSlider"];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"cbhr-bg.png"}
        sliderID="CrossBoundaryHealthRecordSlider"
        sliderTitle={t("Cross-boundary Health Record")}
        sliderTitleID={"texCrossBoundaryHealthRecordSliderTitle"}
        sliderText={t(
          "Share your health records with medical institutions outside Hong Kong that are specified and certified by the Health Bureau"
        )}
        sliderTextID="textCrossBoundaryHealthRecordSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale} one-slide`}
        id="MainContent"
      >
        {phoneSlider}

        <div
          id="health-program-cbhr"
          className="container-fluid second-feature-section last-section"
        >
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textHealthProgrammeSection1Title"
                  className="second-feature-title"
                >
                  {t(
                    "Facilitate your cross-border healthcare consultations Promote continuity of care"
                  )}
                </div>
                <div
                  id="textHealthProgrammeSection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "Eligible Elderly Healthcare Voucher users can now share the health records with designated healthcare institutions"
                  )}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1HealthProgrammeSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/health-program_cb_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2HealthProgrammeSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(`./resources/images/health-program_cb_2.png`)}
                  alt=" "
                />
                <img
                  id="img3HealthProgrammeSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/health-program_p1_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-down"
          data-aos-offset="-300"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoHealthProgramme"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/nLH_Ls3Nk6U"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(CBHRPage);
