import React, { Component } from "react";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";
import CommonDatePicker from "../../CommonDatePicker";
import moment from "moment";
import { getSchoolRankings } from "../../../apis";
import { replaceText } from "../../../resources/utils/stringUtils";
import { isDateValid, isNullOrEmpty } from "../../../resources/utils/validator";
import {
  getCurrentLang,
  getVaccineCalculationStatus,
} from "../../../resources/utils/commonUtils";
import "../../../resources/styles/elifeParklist.scss";

const areas = ["HK", "KL", "NT"];
const parks = [
  {
    nameTc: "鴨脷洲公園",
    addressTc: "香港鴨脷洲鴨脷洲大街",
    nameEn: "Ap Lei Chau Park",
    addressEn: "Ap Lei Chau Main Street, Ap Lei Chau, Hong Kong",
    area: "HK",
  },
  {
    nameTc: "鴨脷洲公園2222",
    addressTc: "香港鴨脷洲鴨脷洲大街",
    nameEn: "Ap Lei Chau Park2222",
    addressEn: "Ap Lei Chau Main Street, Ap Lei Chau, Hong Kong",
    area: "HK",
  },
  {
    nameTc: "九龍公園",
    addressTc: "尖沙咀",
    nameEn: "Kowloon Park",
    addressEn: "Tsim Sha Tsui",
    area: "KL",
  },
  {
    nameTc: "新界公園",
    addressTc: "新界",
    nameEn: "New Territories Park",
    addressEn: "New Territories",
    area: "NT",
  },
];

class ElifeParklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parks: parks,
      filteredParks: [],
      area: "HK",
      search: "",
    };
    this.handleSwitchArea = this.handleSwitchArea.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
  }
  componentDidMount() {
    this.handleUpdateFilteredParks();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.area !== prevState.area ||
      this.state.parks !== prevState.parks ||
      this.state.search !== prevState.search
    ) {
      this.handleUpdateFilteredParks();
    }
  }

  handleSwitchArea(area) {
    this.setState({
      area,
    });
  }

  handleInputChange(event) {
    this.setState({
      search: event.target.value,
    });
  }

  handleSearch(e) {
    e.preventDefault();
    this.handleUpdateFilteredParks();
  }

  handleUpdateFilteredParks() {
    const { area, search } = this.state;
    let filteredParks = this.state.parks.filter((park) => {
      // Filter by area
      if (area !== "ALL" && park.area !== area) {
        return false;
      }

      // Search from name and address
      if (search) {
        const nameTc = park.nameTc.toLowerCase();
        const nameEn = park.nameEn.toLowerCase();
        const addressTc = park.addressTc.toLowerCase();
        const addressEn = park.addressEn.toLowerCase();
        const searchLower = search.toLowerCase();
        if (
          nameTc.indexOf(searchLower) === -1 &&
          nameEn.indexOf(searchLower) === -1 &&
          addressTc.indexOf(searchLower) === -1 &&
          addressEn.indexOf(searchLower) === -1
        ) {
          return false;
        }
      }

      return true;
    });
    this.setState({
      filteredParks,
    });
  }

  handleClearSearch() {
    this.setState({
      search: "",
    });
  }

  render() {
    const { locale, t } = this.props;

    return (
      <div
        className={`page-elife-parklist page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="hero-banner">
          <img
            className="desktop-show"
            src={load(
              `./resources/images/elife/${locale}/life-park-list-banner-desktop.jpg`
            )}
          />
          <img
            className="mobile-show"
            src={load(
              `./resources/images/elife/${locale}/life-park-list-banner-mobile.jpg`
            )}
          />
        </div>

        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="main-container">
                  <div className="search">
                    <div className="title">{t("searchPark")}</div>
                    <div className="input">
                      <form onSubmit={this.handleSearch}>
                        <input
                          type="text"
                          placeholder={t("pleaseEnterParkName")}
                          value={this.state.search}
                          onChange={this.handleInputChange}
                        />
                      </form>
                      {isNullOrEmpty(this.state.search) ? (
                        <img
                          className="ico-search"
                          src={load(
                            `./resources/images/elife-parklist/ico-search.png`
                          )}
                          onClick={this.handleSearch}
                        />
                      ) : (
                        <img
                          className="ico-clear"
                          src={load(
                            `./resources/images/elife-parklist/ico-clear.png`
                          )}
                          onClick={this.handleClearSearch}
                        />
                      )}
                    </div>
                  </div>

                  <div className="tabs">
                    {areas?.map((area) => {
                      return (
                        <div
                          className={`tab ${
                            this.state.area === area ? "active" : ""
                          }`}
                          onClick={() => {
                            this.handleSwitchArea(area);
                          }}
                        >
                          {t("area." + area)}
                        </div>
                      );
                    })}
                  </div>

                  <div className="items">
                    {this.state.filteredParks.length === 0 && (
                      <div className="no-result">{t("noRelatedPark")}</div>
                    )}
                    {this.state.filteredParks?.map((park) => {
                      const name = locale === "en" ? park.nameEn : park.nameTc;
                      const address =
                        locale === "en" ? park.addressEn : park.addressTc;
                      return (
                        <div className="item">
                          <div className="name">{name}</div>
                          <div className="address">{address}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="footer-image display-desktop"
          src={load(`./resources/images/elife-parklist/footer-desktop.png`)}
        />
        <img
          className="footer-image display-mobile"
          src={load(`./resources/images/elife-parklist/footer-mobile.png`)}
        />
        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(ElifeParklist);
